import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LandingGuard implements CanLoad {

  constructor(private nav: NavController) {

  }


  canLoad(
    route: Route,
    segments: UrlSegment[]): Promise<boolean> {
    return new Promise((resolve) => {

      if (localStorage.getItem('login') == null || localStorage.getItem('login') == '' || localStorage.getItem('login') == undefined) {
        resolve(true)
      } else {
        resolve(false)
        this.nav.navigateRoot('tabs/tab1')
      }



    })
  }
}
