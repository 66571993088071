import { Component, ViewChild } from '@angular/core';
import { baseUrl, sgUrl, FIREBASE_CONFIG } from 'src/environments/environment';
import firebase from 'firebase/app';
import 'firebase/auth';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { DataService } from './data.service';
import { AltomateService } from './altomate.service';
import { Market } from '@ionic-native/market/ngx';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Options } from './options';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { UpdatePage } from './update/update.page';
import * as moment from 'moment'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  currentPlatform = ''
  bundle = {}
  userInfo = {}
  public optionsList2: Options[] = [
    {
      value: `Where does it come from ?
        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.`,
    },
    {
      value: `Where can I get some?
        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.`,
    },
    { value: 'Bitter Gourd' },
    { value: 'Pumpkin' },
    { value: 'Bottle Gourd' },
  ];

  weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  dates = []



  companies = []
  default = ''
  userid = ''
  firstInit = true
  constructor(
    private dataService: DataService,
    private platform: Platform,
    private altomateService: AltomateService,
    private market: Market,
    private http: HttpClient,
    private router: Router,
    private nav: NavController,
    private fcm: FCM,
    private modalCtrl: ModalController,
  ) {

    let iosVersion = '010003'
    let androidVersion = '010003'

    let days = []

    let initialPoint = moment()

    for (let i = 0; i < 7; i++) {
      days.push(moment(initialPoint).day(this.weekDays[i]));
    }

    days.forEach(day => this.dates.push(day.format('DD-MM-YYYY')));

    firebase.initializeApp(FIREBASE_CONFIG);
    this.platform.ready().then(() => {
      if (!this.platform.is('mobileweb') && this.platform.is('android')) {
        firebase.database().ref('/versioning/android/' + androidVersion).on('value', data => {
          if (data.val() == false) {
            setTimeout(() => {
              // alert('Please Update Your App in App Store')
              // this.market.open('com.altomate.io');
              // navigator['app'].exitApp();
              this.showUpdate('com.altomate.io')
            }, 0);
          }
        })
      }

      if (!this.platform.is('mobileweb') && (this.platform.is('ios'))) {

        firebase.database().ref('/versioning/ios/' + iosVersion).on('value', data => {
          if (data.val() == false) {
            setTimeout(() => {
              this.showUpdate('id6449769620')
            }, 0);
          }
        })
      }



      if (!this.platform.is('mobileweb') && (this.platform.is('ios'))) {
        this.fcm.requestPushPermission({

          ios9Support: {
            timeout: 10,  // How long it will wait for a decision from the user before returning `false`
            interval: 1, // How long between each permission verification
          }
        }).then(a => { console.log(a) }).catch(e => {
          console.log(e)
        })
      }





      // function(){
      //     $("#ddlViewBy").trigger("liszt:open");
      // },
      // function(){
      //     $(this).trigger("click");
      // }
      // ($('select') as any).selectpicker();


      this.currentPlatform = this.platform.is('ios') ? 'ios' : 'android'

      // firebase.database().ref('bundle').once('value', bund => {
      //   this.bundle = bund.val()
      //   firebase.database().ref('versioning/' + this.currentPlatform + '/' + currentVersion).once('value', version => {
      //     if (version.val() !== true) {

      //     }
      //   })
      // })


      this.dataService.applicationDetails.subscribe(async (res) => {
        this.companies = res.map(r => ({ country: r['country'], id: r['id'], name: r['confirmedName'] || (r['type'] == 'new' ? r['company_names'] ? r['company_names'][0] + ' Sdn. Bhd.' : 'Sdn. Bhd.' : r['company_name'] + ' Sdn. Bhd.' || 'Sdn. Bhd.') }))

        this.companies = this.companies.filter(vb => vb['id'] != 'undefined')
        if (this.companies.length > 0) {

        }
      })




      // this.getFCMToken()
      this.altomateService.getRequestDetails().then((res: any) => {
        this.dataService.updateRequestDetails(res)
      })

      // setTimeout(() => {
      //   this.altomateService.pleasewait('Please wait..', 'Checking your authentication')
      // }, 0);


      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {

          localStorage.setItem('login', '1234')
          // this.altomateService.swalclose()
          // this.nav.navigateRoot('tabs/tab1')

          // this.nav.navigateRoot('tabs/tab1')
          if (!this.platform.is('mobileweb') && (this.platform.is('android') || this.platform.is('ios'))) {
            this.fcm.subscribeToTopic(user.uid || '').catch((error) => {
              console.log(error)
            })
          }

          if (localStorage.getItem('XERO_ACCESS_TOKEN_KEY') && localStorage.getItem('XERO_REFRESH_TOKEN_KEY')) {
            await this.altomateService.getXeroConnectedOrganisation()
          }

          this.altomateService.pleasewait('Please wait...', 'Getting your data...')

          firebase.database().ref('users/' + user.uid).on('value', userInfo => {
            let userDetails = userInfo.val()
            this.dataService.updateUser(userDetails)
          })

          firebase.database().ref('users/' + user.uid).once('value', userInfo => {
            this.userid = user.uid
            let userDetails = userInfo.val()
            this.userInfo = userInfo.val()
            this.dataService.updateUser(userDetails)

            firebase.database().ref('users/' + user.uid + '/companies').orderByChild('id').once('child_added', apps => {
              if (apps.val()['id'] != 'undefined') {
                this.altomateService.getApplicationDetails(apps.val()['id'])
              }

            })

            firebase.database().ref('users/' + user.uid + '/default').on('value', def => {
              if (def.exists()) {

                this.default = def.val()
                if (document.getElementsByClassName('ng-value').length > 0) {
                  setInterval(() => {
                    if (this.companies.length > 0) {
                      let valueContainer = document.querySelector('#container.ng-select-single .ng-select-container .ng-value-container');
                      if (valueContainer) {
                        let firstChild: any = valueContainer.firstElementChild;
                        if (firstChild && firstChild.tagName === 'IMG') {

                          firstChild['src'] = this.companies.find(cs => cs['country'] == 'Singapore') ? 'assets/img/singapore.webp' : 'assets/img/malaysia.webp';
                        } else {
                          let imgElement = document.createElement('img');
                          imgElement.src = this.companies.find(cs => cs['country'] == 'Singapore') ? 'assets/img/singapore.webp' : 'assets/img/malaysia.webp';
                          imgElement.style.marginRight = '10px';
                          imgElement.style.width = '28px';
                          imgElement.style.height = '25px';
                          imgElement.style.objectFit = 'cover';
                          imgElement.style.position = 'absolute';
                          imgElement.style.left = '10px';
                          imgElement.style.marginBottom = '2px';
                          valueContainer.insertBefore(imgElement, valueContainer.firstChild);
                        }
                      }

                    }

                  }, 1000)
                }



                firebase.database().ref('applications/' + def.val()).on('value', apps2 => {
                  if (apps2.exists) {
                    if (this.altomateService.firstInitial2 == true) {
                      this.altomateService.firstInitial2 = false
                      this.altomateService.swalclose()
                    }

                    let defaultApplication = apps2.val()
                    this.dataService.removeDocuments(defaultApplication['dealId'])
                    let documents = defaultApplication['documents'] ? Object.values(defaultApplication['documents'] || {}).map((s: any) => ({ ...s, name: (s['id'] == 'declaration' ? s.name + ' - ' + (userDetails['first_name'] + ' ' + userDetails['last_name']) : s.name), companyID: defaultApplication['companyID'], confirmedName: defaultApplication['confirmedName'], applicationId: defaultApplication['id'], dealId: defaultApplication['dealId'], folderId: defaultApplication['folderId'] })) : ''
                    this.dataService.updatedocuments(documents)

                    // this.altomateService.getApplicationDetails(defaultApplication['id'])

                    if (!this.altomateService.isNullOrEmpty(defaultApplication['xeroContactId'])) {
                      // this.http.post(baseUrl + '/getInvoiceByContactId', { contactId: defaultApplication['xeroContactId'] }).subscribe((inv) => {
                      //   this.dataService.updateInvoices(inv['invoices'])
                      //   console.log(inv)
                      // })
                      if (defaultApplication['country'] == 'Malaysia') {
                        this.http.post(baseUrl + '/getInvoiceByContactId', { contactId: defaultApplication['xeroContactId'] }).subscribe((inv) => {
                          this.dataService.updateInvoices(inv['invoices'])
                        })
                      } else if (defaultApplication['country'] == 'Singapore') {
                        this.http.post(sgUrl + '/getInvoiceByContactId', { contactId: defaultApplication['xeroContactId'] }).subscribe((inv) => {
                          this.dataService.updateInvoices(inv['invoices'])
                        })
                      }

                    }

                    if (!this.altomateService.isNullOrEmpty(defaultApplication['folderId'])) {
                      this.http.post(baseUrl + '/getFolder', { storageId: defaultApplication['folderId'] }).subscribe((fol) => {
                        if (fol['data']) {
                          this.dataService.updateFolders(fol['data'])
                        }
                      })
                    }

                    if (!this.altomateService.isNullOrEmpty(defaultApplication['workgroup'])) {
                      // this.http.post(baseUrl + '/getEvent', { storageId: defaultApplication['folderId'] }).subscribe((fol) => {
                      //   if (fol['data']) {
                      //     this.dataService.updateFolders(fol['data'])
                      //   }
                      // })
                      let startDate = moment().startOf('month').format('YYYY-MM-DD')
                      let endDate = moment().endOf('month').format('YYYY-MM-DD')
                      this.altomateService.getCalendarEvent(defaultApplication['workgroup'], startDate, endDate)
                      this.altomateService.getCurrentWeekCalendarEvent(defaultApplication['workgroup'], this.dates[0], this.dates[this.dates.length - 1])
                    }

                    if (!this.altomateService.isNullOrEmpty(defaultApplication['companyID'])) {

                      firebase.database().ref('requestdeal/' + defaultApplication['companyID']).on('value', requests => {
                        this.dataService.updateRequestDeal(Object.values(requests.val() || {}))

                        let arr = Object.values(requests.val() || {})
                        for (let x = 0; x < arr.length; x++) {
                          if (arr[x]['documents']) {
                            let documents = Object.values(arr[x]['documents'] || {}).map((r2: any) => ({ ...r2, companyID: defaultApplication['companyID'], confirmedName: defaultApplication['confirmedName'], applicationId: defaultApplication['id'], dealId: arr[x]['dealId'], folderId: defaultApplication['folderId'] })) || []
                            this.dataService.updatedocuments(documents)
                          }
                        }
                      })
                    }


                  }
                })

                // firebase.database().ref('applications/').orderByChild('id').equalTo(this.default).on('child_changed', apps2 => {
                //   if (apps2.exists) {

                //     let defaultApplication = apps2.val()
                //     let documents: any = defaultApplication['documents'] ? Object.values(defaultApplication['documents'] || {}).map((s: any) => ({ ...s, companyID: defaultApplication['companyID'], confirmedName: defaultApplication['confirmedName'], applicationId: defaultApplication['id'], dealId: defaultApplication['dealId'], folderId: defaultApplication['folderId'] })) : ''
                //     documents = documents.filter(d => d['signature'][user['uid']] == false)
                //     this.dataService.removeDocuments(defaultApplication['dealId'])
                //     this.dataService.updatedocuments(documents)
                //   }
                // })


                // firebase.database().ref('applications').orderByChild('id').equalTo(def.val()).on('child_changed', apps2 => {

                //   console.log(apps2)
                //   if (apps2.exists) {

                //     let defaultApplication = apps2.val()
                //     // console.log(defaultApplication)
                //     let documents: any = defaultApplication['documents'] ? Object.values(defaultApplication['documents'] || {}).map((s: any) => ({ ...s, companyID: defaultApplication['companyID'], confirmedName: defaultApplication['confirmedName'], applicationId: defaultApplication['id'], dealId: defaultApplication['dealId'], folderId: defaultApplication['folderId'] })) : ''
                //     documents = documents.filter(d => d['signature'][user['uid']] == false)
                //     this.dataService.removeDocuments(defaultApplication['dealId'])
                //     this.dataService.updatedocuments(documents)
                //   }
                // })
              } else {
                if (this.altomateService.firstInitial2 == true) {
                  this.altomateService.firstInitial2 = false
                  this.altomateService.swalclose()
                }
              }
            })
          })

        } else {

        }
      })
    })
  }


  // monthSelected(ev, input) {
  //   let { _d } = ev;
  //   this.selectYear = _d;
  //   console.log(this.selectYear)
  //   this.picker.close()
  // }


  openMarket() {
    this.market.open(this.bundle[this.currentPlatform])
  }

  pathto(x) {
    this.router.navigate([x], { replaceUrl: true });
    // this.nav.navigateRoot();
  }

  getFCMToken() {
    // this.fcm.getToken().then(token => {
    //   console.log(token)
    // }).catch((error) => {
    //   console.log('helo')
    // })
  }


  update() {
    firebase.database().ref('users/' + this.userid + '/default').set(this.default).then(() => {
      window.location.reload()
    })
  }

  tabs = [
    {
      icon: 'assets/svg/Home.svg',
      selectedicon: 'assets/svg/Home-Green.svg',
      title: 'Home',
      path: 'tabs/tab1'
    },
    {
      icon: 'assets/svg/Accounting.svg',
      selectedicon: 'assets/svg/Accounting-Green.svg',
      title: 'Accounting',
      path: 'tabs/tab2'
    },
    {
      icon: 'assets/svg/Billing.svg',
      selectedicon: 'assets/svg/Billing-Green.svg',
      title: 'Billing',
      path: 'tabs/tab3'
    },
    {
      icon: 'assets/svg/Company.svg',
      selectedicon: 'assets/svg/Company-Green.svg',
      title: 'Company',
      path: 'tabs/tab4'
    }
  ]

  routerChecker(path) {

    if (this.router.url.includes(path)) {
      return true
    }

    return false
  }

  hideTab() {
    if (this.router.url.includes('/mycompany-start')) {
      return true
    }

    if (this.router.url.includes('/transfer')) {
      return true
    }

    if (this.router.url.includes('/document')) {
      return true
    }

    if (this.router.url.includes('/business-address')) {
      return true
    }

    if (this.router.url.includes('/company-secretarial')) {
      return true
    }

    if (this.router.url.includes('/all-requests')) {
      return true
    }

    if (this.router.url.includes('/all-files')) {
      return true
    }

    if (this.router.url.includes('/all-documents')) {
      return true
    }

    if (this.router.url.includes('/all-tasks')) {
      return true
    }

    if (this.router.url.includes('/landing')) {
      return true
    }

    if (this.router.url.includes('/start')) {
      return true
    }

    if (this.router.url.includes('/start-register')) {
      return true
    }

    if (this.router.url.includes('/start-login')) {
      return true
    }

    if (this.router.url.includes('/forgot-password')) {
      return true
    }

    if (this.router.url.includes('/document')) {
      return true
    }

    if (this.router.url.includes('/request-create')) {
      return true
    }

    if (this.router.url.includes('/change-password')) {
      return true
    }

    if (this.router.url.includes('/profile')) {
      return true
    }

    if (this.router.url.includes('/kyc')) {
      return true
    }

    if (this.router.url.includes('/bank-selection')) {
      return true
    }


    if (this.router.url.includes('/transactions-detail')) {
      return true
    }

    if (this.router.url.includes('/tab4-applications')) {
      return true
    }

    if (this.router.url.includes('/resetpassword')) {
      return true
    }


    return false
  }

  hideTab2() {
    if (this.router.url.includes('/landing')) {
      return true
    }


    if (this.router.url.includes('/start')) {
      return true
    }

    if (this.router.url.includes('/start-register')) {
      return true
    }

    if (this.router.url.includes('/start-login')) {
      return true
    }

    if (this.router.url.includes('/forgot-password')) {
      return true
    }

    // if (this.router.url.includes('/transactions-detail')) {
    //   return true
    // }

    if (this.router.url.includes('/resetpassword')) {
      return true
    }

    if (this.router.url.includes('/tab4-applications')) {
      return true
    }


    return false
  }



  change(url) {
    this.router.navigate([url], { replaceUrl: true });
  }


  async showUpdate(marketid) {
    const modal = await this.modalCtrl.create({
      component: UpdatePage,
      cssClass: 'updateModal',
      backdropDismiss: false,
      swipeToClose: false,
      componentProps: {
        marketid: marketid
      }
    });


    return await modal.present();
  }

  goDashboard() {
    this.nav.navigateRoot('tabs/tab1')
  }



}
