import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanLoad {
  constructor(private nav: NavController) {

  }


  canLoad(
    route: Route,
    segments: UrlSegment[]): Promise<boolean> {
    return new Promise((resolve) => {
      if (localStorage.getItem('login') == null || localStorage.getItem('login') == '' || localStorage.getItem('login') == undefined) {

        resolve(false)
        this.nav.navigateRoot('landing')
      } else {
        resolve(true)
      }


    })
  }
}
