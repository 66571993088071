import { HttpClient } from '@angular/common/http';
import { Injectable, ViewChild } from '@angular/core';
import { NavController, Platform, ToastController } from '@ionic/angular';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as lodash from 'lodash';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import firebase from 'firebase/app';
import 'firebase/database';
import { DataService } from './data.service';
import { baseUrl } from 'src/environments/environment';
declare var Swal;

@Injectable({
  providedIn: 'root',
})
export class AltomateService {
  constructor(
    private http: HttpClient,
    private toastController: ToastController,
    private safariViewCtrl: SafariViewController,
    private platform: Platform,
    private dataService: DataService,
    private nav: NavController
  ) {}

  firstInitial = true;
  firstInitial2 = true;
  xeroDetails = {};

  phoneValidater(phone) {
    return isValidPhoneNumber(phone);
  }

  widther() {
    return this.platform.width() >= 768 ? 'desktop' : 'mobile';
  }

  emailValidator(email) {
    if (email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    } else {
      return false;
    }
  }

  getPhoneNumbers() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/phone.json').subscribe((res) => {
        resolve(res);
      });
    });
  }

  getNatureOfBusiness() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/nature_of_business.json').subscribe((res) => {
        resolve(res);
      });
    });
  }

  getSgSSIC() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/ssic_sg.json').subscribe((res) => {
        resolve(res);
      });
    });
  }

  getFinancialYear() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/financial_year_end.json').subscribe((res) => {
        resolve(res);
      });
    });
  }

  getSourceOfFund() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/sources_of_funds.json').subscribe((res) => {
        resolve(res);
      });
    });
  }

  getBanks() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/bank.json').subscribe((res) => {
        resolve(res);
      });
    });
  }

  getCountryState() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/malaysia_states.json').subscribe((res) => {
        resolve(res);
      });
    });
  }

  getRaces() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/races.json').subscribe((res) => {
        resolve(res);
      });
    });
  }

  getPepTypes() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/pep_types.json').subscribe((res) => {
        resolve(res);
      });
    });
  }

  getRequestDetails() {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref('requestsdetails')
        .once('value', (data) => {
          resolve(data.val());
        });
    });
  }

  getApplications(id) {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref('applications/' + id)
        .once('value', (data) => {
          resolve(data.val());
        });
    });
  }

  getRequestDeal(id) {
    this.dataService.applicationDetails.subscribe((res) => {
      let applications = res;
      let defaultCompany = applications.find((a) => a['id'] == id);
      if (defaultCompany) {
        if (defaultCompany['companyID'] && defaultCompany['companyID'] != '') {
          firebase
            .database()
            .ref('requestdeal/' + defaultCompany['companyID'])
            .once('value', (requests) => {
              this.dataService.updateRequestDeal(
                Object.values(requests.val() || {})
              );
              let documents = lodash.flatten(
                Object.values(requests.val() || {}).map(
                  (r) =>
                    Object.values(r['documents'] || {}).map((r2: any) => ({
                      ...r2,
                      companyID: defaultCompany['companyID'],
                      confirmedName: defaultCompany['confirmedName'],
                      applicationId: defaultCompany['id'],
                      dealId: r['dealId'],
                      folderId: defaultCompany['folderId'],
                    })) || []
                )
              );
              this.dataService.updatedocuments(documents);
            });
        }
      }
    });
  }

  getApplicationDetails(id) {
    firebase
      .database()
      .ref('applications/' + id)
      .once('value', (data) => {
        if (data.exists()) {
          if (!data.val()['companyID']) {
            this.dataService.addNewApplication(data.val());
          } else {
            this.getCompanyDetails(data.val());
          }
        }
      });
  }

  fileChange(event) {
    return new Promise((resolve, reject) => {
      if (
        event.target.files &&
        event.target.files[0] &&
        event.target.files[0].size < 10485768
      ) {
        const file = event.target.files[0];
        let extension = event.target.files[0].type;
        if (extension == 'application/pdf') {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result
              .toString()
              .replace(';base64,', 'thisisathingtoreplace;');
            let imgarr = base64String.split('thisisathingtoreplace;');
            event.target.value = '';

            resolve({
              success: true,
              data: {
                image: reader.result.toString(),
                files: file,
                base64String: imgarr[1],
              },
            });
          };

          reader.readAsDataURL(file);
        } else {
          var canvas = <HTMLCanvasElement>document.createElement('canvas');
          var ctx = canvas.getContext('2d');
          var maxW = 400;
          var maxH = 400;
          var img = new Image();
          img.onload = () => {
            var iw = img.width;
            var ih = img.height;
            var scale = Math.min(maxW / iw, maxH / ih);
            var iwScaled = iw * scale;
            var ihScaled = ih * scale;
            canvas.width = iwScaled;
            canvas.height = ihScaled;
            ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
            event.target.value = '';

            let imagec = canvas.toDataURL();
            let imgggg = imagec.replace(';base64,', 'thisisathingtoreplace;');
            let imgarr = imgggg.split('thisisathingtoreplace;');
            let base64String = imgarr[1];

            resolve({
              success: true,
              data: { image: imagec, files: file, base64String: base64String },
            });
          };

          img.src = URL.createObjectURL(file);
        }

        // var canvas = <HTMLCanvasElement>document.createElement("canvas");
        // var ctx = canvas.getContext("2d");
        // var cw = canvas.width;
        // var ch = canvas.height;
        // var maxW = 400;
        // var maxH = 400;
        // var type = event.target.files[0].type;
        // var file = event.target.files[0]
        // var img = new Image;
        // img.onload = () => {
        //   var iw = img.width;
        //   var ih = img.height;
        //   var scale = Math.min((maxW / iw), (maxH / ih));
        //   var iwScaled = iw * scale;
        //   var ihScaled = ih * scale;
        //   canvas.width = iwScaled;
        //   canvas.height = ihScaled;
        //   ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        //   event.target.value = ''

        //   let imagec = canvas.toDataURL();
        //   let imgggg = imagec.replace(';base64,', "thisisathingtoreplace;")
        //   let imgarr = imgggg.split("thisisathingtoreplace;")
        //   let base64String = imgarr[1]

        //   resolve({ success: true, data: { image: imagec, files: file, base64String: base64String } })
        // }

        // img.src = URL.createObjectURL(event.target.files[0]);
      } else {
        reject({
          success: false,
          message:
            '"Your Current Image Too Large, " + event.target.files[0].size / (10241024) + "MB! (Please choose file lesser than 8MB)"',
        });
        alert(
          'Your Current Image Too Large, ' +
            event.target.files[0].size / 10241024 +
            'MB! (Please choose file lesser than 8MB)'
        );
      }
    });
  }

  pictureToLink(folder, file, extraParametes) {
    return new Promise((resolve, reject) => {
      if (file != null && file != undefined && file != '') {
        let formData = new FormData();
        formData.append('file', file, file.name);

        const randomKey =
          Date.now().toString(36).substring(0, 5) +
          Math.random().toString(36).substr(2).substring(0, 4);

        formData.append('fileName', randomKey + file.name);

        this.http
          .post(baseUrl + '/uploadFileToGS', formData, {})
          .subscribe((res) => {
            resolve({
              success: true,
              link: res['Location'],
              extraParametes: extraParametes,
            });
          });
      }
    });
  }

  base64ToLink(folder, base64) {
    return new Promise((resolve, reject) => {
      if (base64 != null && base64 != undefined && base64 != '') {
        if (base64.startsWith('https://')) {
          resolve({ success: true, link: base64 });
          return;
        }

        let splitArr = base64
          .replace(';base64,', 'thisisathingtoreplace;')
          .split('thisisathingtoreplace;');

        const type = splitArr[0].split(';')[0].split('/')[1];

        let documentExtension =
          splitArr[0].split(';')[0].split('/')[0] == 'data:image'
            ? type
            : 'pdf';

        const randomKey =
          Date.now().toString(36).substring(0, 5) +
          Math.random().toString(36).substr(2).substring(0, 4) +
          '.' +
          documentExtension;

        this.http
          .post(
            baseUrl + '/uploadBaseToSG',
            {
              base64: splitArr[1],
              fileName: randomKey,
              contentType:
                splitArr[0].split(';')[0].split('/')[0] == 'data:image'
                  ? `image/${type}`
                  : 'application/pdf',
            },
            {}
          )
          .subscribe((res) => {
            resolve({ success: true, link: res['Location'] });
            return;
          });
      }
    });
  }

  keyToLink(folder, base64, fieldId, filetype, contactId, uid) {
    return new Promise((resolve, reject) => {
      if (base64 != null && base64 != undefined && base64 != '') {
        if (base64.startsWith('https://')) {
          resolve({ success: true, link: base64 });
          return;
        }

        let splitArr = base64
          .replace(';base64,', 'thisisathingtoreplace;')
          .split('thisisathingtoreplace;');

        const type = splitArr[0].split(';')[0].split('/')[1];

        let documentExtension =
          splitArr[0].split(';')[0].split('/')[0] == 'data:image'
            ? type
            : 'pdf';

        const randomKey =
          Date.now().toString(36).substring(0, 5) +
          Math.random().toString(36).substr(2).substring(0, 4) +
          '.' +
          documentExtension;

        this.http
          .post(
            baseUrl + '/uploadKYC',
            {
              uid: uid,
              type: filetype,
              contactId: contactId,
              fieldId: fieldId,
              base64: splitArr[1],
              fileName: randomKey,
              contentType:
                splitArr[0].split(';')[0].split('/')[0] == 'data:image'
                  ? `image/${type}`
                  : 'application/pdf',
            },
            {}
          )
          .subscribe((res) => {
            resolve({ success: true, link: res['Location'] });
            return;
          });
      }
    });
  }

  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  lengthOf(x) {
    x = x || [];
    x = Array.isArray(x) ? x : Object.values(x);

    return x.length;
  }

  showMessage(title, description, icon) {
    Swal.fire({
      title: title,
      text: description,
      icon: icon,
      showCancelButton: false,
      timerProgressBar: false,
    });
  }

  pleasewait(title, text) {
    Swal.fire({
      title: title,
      html: text,
      timerProgressBar: false,
      allowOutsideClick: false,
      confirmButtonText: false,
      timer: 5000,

      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  showContent(title, element) {
    Swal.fire({
      title: title,
      html: element,
      timer: 10000,
    }).then(() => {
      this.nav.pop();
    });
  }

  swal_button(title, text, icon) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Yes, I'm sure!",
      }).then((result) => {
        if (result.isConfirmed) {
          resolve('Confirm');
        } else {
          resolve('No');
        }
      });
    });
  }

  swalclose() {
    Swal.close();
  }

  async presentToast(message, position, duration, çolor) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: position,
      color: çolor,
    });

    toast.present();
  }

  isNullOrEmpty(...args: any[]) {
    let temp = args[0];

    if (temp === undefined || temp === null || temp === '') {
      return true;
    }

    if (typeof temp === 'string') {
      if (temp === undefined || temp === null || temp === '') {
        return true;
      }

      return false;
    }

    if (typeof temp === 'object') {
      let variable = args[1];

      for (let i = 0; i < variable.length; i++) {
        if (
          temp[variable[i]] === undefined ||
          temp[variable[i]] === null ||
          temp[variable[i]] === ''
        ) {
          return true;
        }
      }

      return false;
    }
  }

  openUrl(url) {
    this.safariViewCtrl
      .isAvailable()
      .then(async (available: boolean) => {
        if (available) {
          this.safariViewCtrl
            .show({
              url: url,
            })
            .subscribe(
              (result: any) => {
                if (result.event === 'opened') console.log('Opened');
                else if (result.event === 'loaded') console.log('Loaded');
                else if (result.event === 'closed') console.log('Closed');
              },
              (error: any) => console.error(error)
            );
        } else {
          window.open(url, '_system');
        }
      })
      .catch(async (error) => {
        window.open(url, '_system');
      });
  }

  getCompanyDetails(details) {
    this.http.get('https://crm.softon.io/rest/427/ucaohz32xttqneoe/lists.element.get.json?IBLOCK_TYPE_ID=lists&IBLOCK_ID=141&FILTER[PROPERTY_531]=' + details['companyID'] + '&FILTER[PROPERTY_533]=305').subscribe((list) => {
      let companyList = list['result'].filter(x => x['NAME'] == 'Altomate' && Object.values(x['PROPERTY_527'])[0] === '323' || Object.values(x['PROPERTY_527'])[0] === '327' || Object.values(x['PROPERTY_527'])[0] === '325' || Object.values(x['PROPERTY_527'])[0] === '339' || Object.values(x['PROPERTY_527'])[0] === '341')

      let shareholdershare = {}
      let application = details

      for (let h = 0; h < companyList.length; h++) {
        let contactId: any = Object.values(companyList[h]['PROPERTY_525'])[0]
        shareholdershare[contactId] = Object.values(companyList[h]['PROPERTY_529'])[0]
      }


      this.http.get('https://crm.softon.io/rest/427/ucaohz32xttqneoe/crm.company.get.json?id=' + details['companyID'], {}).subscribe((a) => {
        let result = a['result']

        application['address_company'] = {}
        application['address_company']['line1'] = result.UF_CRM_1585711453
        application['address_company']['line2'] = result.UF_CRM_1585711467
        application['address_company']['city'] = result.UF_CRM_1585711495
        application['address_company']['state'] = result.UF_CRM_1585711522
        application['address_company']['postcode'] = result.UF_CRM_1585711563

        application['mailing_address'] = {}
        application['mailing_address']['line1'] = result.UF_CRM_1622100720123
        application['mailing_address']['line2'] = result.UF_CRM_1622100728419
        application['mailing_address']['city'] = result.UF_CRM_1622100753319
        application['mailing_address']['state'] = result.UF_CRM_1622100743624
        application['mailing_address']['postcode'] = result.UF_CRM_1622100770161

        application['description'] = result.UF_CRM_1626073898671
        application['date_of_incorporation'] = result.UF_CRM_1612189416003
        application['ssm'] = result.UF_CRM_1585708913204
        application['company_email'] = result.EMAIL
        application['phone_country'] = result.PHONE
        application['phone_number'] = result.PHONE
        application['confirmedName'] = result.TITLE

        application['register_address'] = {}
        application['register_address']['line1'] = result.UF_CRM_1581838992248
        application['register_address']['line2'] = result.UF_CRM_1581839002479
        application['register_address']['city'] = result.UF_CRM_1581839013375
        application['register_address']['state'] = result.UF_CRM_1581839032051
        application['register_address']['postcode'] = result.UF_CRM_1581839059588

        application['share_number'] = result.UF_CRM_1626164589687
        application['busi_nature'] = [result.UF_CRM_1617073997 == '' ? 'N/A' : result.UF_CRM_1617073997, result.UF_CRM_1618383404750 == '' ? 'N/A' : result.UF_CRM_1618383404750, result.UF_CRM_1618383420173 == '' ? 'N/A' : result.UF_CRM_1618383420173]
        application['secretaryid'] = result.UF_CRM_1582946936

        application['shareholdercontacts'] = result.UF_CRM_1617264824
        application['directorcontacts'] = result.UF_CRM_1617085268
        application['corporate_shareholder'] = result.UF_CRM_1620006210
        application['corporate_shareholder_contactId'] = result.UF_CRM_1626336621

        application['shareholders'] = []
        application['directors'] = []
        //fusioneta change (start)
        application['financial_year_end'] = result.UF_CRM_1581839855748
        application['auditor_firm_name'] = result.UF_CRM_1638151716508
        application['tax_agent'] = result.UF_CRM_1565794296
        //fusioneta change (end)

        let processArr = []

        for (let i = 0; i < application['shareholdercontacts'].length; i++) {
          let contactId: any = application['shareholdercontacts'][i]
          processArr.push(this.getInfo(application['shareholdercontacts'][i], i, 'shareholders', shareholdershare[contactId]))
        }

        for (let i = 0; i < application['directorcontacts'].length; i++) {
          processArr.push(this.getInfo(application['directorcontacts'][i], i, 'directors', null))
        }

        for (let i = 0; i < application['corporate_shareholder_contactId'].length; i++) {
          let contactId: any = application['corporate_shareholder_contactId'][i]
          processArr.push(this.getCompanyInfo(application['corporate_shareholder_contactId'][i], application['shareholdercontacts'].length + i, 'shareholders', application['corporate_shareholder'][i], shareholdershare[contactId]))
        }

        Promise.all(processArr).then((pr) => {


          application['shareholders'] = pr.filter(t => t['type'] == 'shareholders').map(m => m['info'])
          application['directors'] = pr.filter(t => t['type'] == 'directors').map(m => m['info'])
           // <----- FusionETA - Alvin (27 October 2023) - change 'secretaryid' to 'companyID' - Start ----- -->
          this.http.get('https://crm.softon.io/rest/427/ucaohz32xttqneoe/crm.company.get.json?id=' + application['companyID'], {}).subscribe((a2) => {
            // <----- FusionETA - Alvin (27 October 2023) - change 'secretaryid' to 'companyID' - End ----- -->
            let result2 = a2['result']
            application['secretarytitle'] = result2.UF_CRM_1622975012110
            application['secretaryemail'] = result2.EMAIL
            application['secretaryphone'] = result2.PHONE
            this.dataService.addNewApplication(application)
          })
        })
      })
    })
  }

  getCalendarEvent(workgroupId, startDate, endDate) {
    this.http
      .post(baseUrl + '/getEvent', {
        groupId: workgroupId,
        startDate: startDate,
        endDate: endDate,
      })
      .subscribe((res) => {
        this.dataService.updateCalendarEvent(res['message']);
      });
  }

  getCurrentWeekCalendarEvent(workgroupId, startDate, endDate) {
    this.http
      .post(baseUrl + '/getEvent', {
        groupId: workgroupId,
        startDate: startDate,
        endDate: endDate,
      })
      .subscribe((res) => {
        this.dataService.updateCurrentWeekCalendarEvent(res['message']);
        console.log(res['message']);
      });
  }

  getInfo(contactId, i, type, share) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          'https://crm.softon.io/rest/427/ucaohz32xttqneoe/crm.contact.get.json?id=' +
            contactId
        )
        .subscribe((a) => {
          let info = {};
          let result = a['result'];
          info['contactId'] = contactId;
          info['show2'] = i == 0 ? true : false;
          info['first_name'] = result['NAME'];
          info['last_name'] = result['LAST_NAME'];
          info['phone'] = result['PHONE'];
          info['email'] = result['EMAIL'];
          info['dob'] = result['BIRTHDATE'];
          info['gender'] = result['UF_CRM_1611739396'];
          info['race'] = result['UF_CRM_1614056837683'];
          info['fund_source'] = result['UF_CRM_1618305986'];
          info['line1'] = result['ADDRESS'];
          info['line2'] = result['ADDRESS_2'];
          info['country'] = result['ADDRESS_COUNTRY'];
          info['state'] = result['ADDRESS_PROVINCE'];
          info['city'] = result['ADDRESS_CITY'];
          info['postcode'] = result['ADDRESS_POSTAL_CODE'];
          info['nationality'] = result['UF_CRM_1614056849056'];

          info['busi_occupation'] = result['UF_CRM_1618821689578'];
          info['passport_expiry'] = result['UF_CRM_1619759755918'];
          result['UF_CRM_1619759755918'] == ''
            ? (info['nric'] = result['UF_CRM_1609226763792'])
            : (info['passport_num'] = result['UF_CRM_1609226763792']);
          info['issuance_country'] = result['UF_CRM_1619759732977'];
          info['no_of_shares'] = share;
          info['altomateId'] = result['UF_CRM_1614153407678'];
          info['position'] = type;

          // this.application[type][i] = info
          resolve({ type: type, info: info });
        });
    });
  }

  getCompanyInfo(contactId, i, type, companyId, share) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          'https://crm.softon.io/rest/427/ucaohz32xttqneoe/crm.contact.get.json?id=' +
            contactId
        )
        .subscribe((a) => {
          let info = {};
          let result = a['result'];
          info['contactId'] = contactId;

          info['first_name'] = result['NAME'];
          info['last_name'] = result['LAST_NAME'];
          info['nric'] = result['UF_CRM_1609226763792'];
          info['phone'] = result['PHONE'];
          info['email'] = result['EMAIL'];
          info['dob'] = result['BIRTHDATE'];
          info['gender'] = result['UF_CRM_1611739396'];
          info['race'] = result['UF_CRM_1614056837683'];
          info['nationality'] = result['UF_CRM_1614056849056'];
          info['busi_occupation'] = result['UF_CRM_1618821689578'];
          info['type'] = 'corporate';
          info['altomateId'] = result['UF_CRM_1614153407678'];
          info['no_of_shares'] = share;
          info['position'] = 'Corporate Representative';

          this.http
            .get(
              'https://crm.softon.io/rest/427/ucaohz32xttqneoe/crm.company.get.json?id=' +
                companyId
            )
            .subscribe((a2) => {
              let result2 = a2['result'];
              info['companyId'] = companyId;
              info['company_email'] = result2['EMAIL'];
              info['company_name'] = result2['TITLE'];
              info['country_origin'] = result2['UF_CRM_1619749672609'];
              info['ssm'] = result2['UF_CRM_1585708913204'];
              info['office_num'] = result2['PHONE'];
              info['incorporation_date'] = result2['UF_CRM_1612189416003'];
              info['fund_source'] = result2['UF_CRM_1623315534757'];
              info['line1'] = result2['UF_CRM_1585711453'];
              info['line2'] = result2['UF_CRM_1585711467'];
              info['city'] = result2['UF_CRM_1585711495'];
              info['state'] = result2['UF_CRM_1585711522'];
              info['postcode'] = result2['UF_CRM_1585711563'];
              // this.application[type][i] = info

              resolve({ type: type, info: info });
            });
        });
    });
  }

  getXeroConnectedOrganisation() {
    return new Promise((resolve) => {
      this.http.post(baseUrl + '/connectedOrganisation', {}).subscribe(
        (result: any) => {
          this.xeroDetails = result[0];
          resolve(true);
        },
        (error) => {
          resolve(true);
        }
      );
    });
  }
}
