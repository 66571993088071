import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as lodash from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

  private userSource = new BehaviorSubject({});
  currentUser = this.userSource.asObservable();

  private calendatSource = new BehaviorSubject([]);
  calendarEvent = this.calendatSource.asObservable();

  private currentWeekkcalendatSource = new BehaviorSubject([]);
  currentWeekcalendarEvent = this.currentWeekkcalendatSource.asObservable();

  private requestSource = new BehaviorSubject({});
  requestDetails = this.requestSource.asObservable();

  private applicationSource = new BehaviorSubject([]);
  applicationDetails = this.applicationSource.asObservable();

  private documentSource = new BehaviorSubject([]);
  documentDetails = this.documentSource.asObservable();

  private requestDealSource = new BehaviorSubject([]);
  requestDeals = this.requestDealSource.asObservable();

  private invoicesSource = new BehaviorSubject([]);
  invoices = this.invoicesSource.asObservable();

  private folderSource = new BehaviorSubject([]);
  folders = this.folderSource.asObservable();


  updateUser(obj) {
    this.userSource.next(obj)
  }

  updateRequestDetails(obj) {
    this.requestSource.next(obj)
  }

  updateFolders(val) {
    this.folderSource.next(val)
  }

  updateInvoices(val) {
    this.invoicesSource.next(val)
  }

  updateCalendarEvent(val) {
    this.calendatSource.next(val)
  }

  updateCurrentWeekCalendarEvent(val){
    this.currentWeekkcalendatSource.next(val)
  }

  addNewApplication(val) {
    // let previous = this.applicationSource.value
    // previous = previous.splice(previous.findIndex(a => a['id'] == val['id']), 1)
    if(val['id'] != undefined && val['id'] != '' && val['id'] != null){
      this.applicationSource.next(this.applicationSource.value.concat([val]))
    }    
  }

  removeAllApplication() {
    this.applicationSource.next([])
  }

  updatedocuments(val) {
    if (val.length > 0) {
      let temp = this.documentSource.value.filter(b => b['dealId'] != val[0]['dealId'])
      this.documentSource.next(lodash.flatten(temp.concat(val)))
    }
  }

  clearDocument() {
    this.documentSource.next([])
  }

  removeDocuments(dealId) {
    let temp = this.documentSource.value.filter(b => b['dealId'] != dealId)
    this.documentSource.next(temp)
  }

  updateRequestDeal(val) {
    if (val.length > 0) {
      // let temp = this.requestDealSource.value.filter(b => b['dealId'] != val[0]['dealId'])
      this.requestDealSource.next(val)
    }
  }



}
