export const environment = {
  production: true
};

export const baseUrl = 'https://us-central1-fusioneta-softon-test.cloudfunctions.net/widgets3'
export const sgUrl = 'https://us-central1-fusioneta-softon-test.cloudfunctions.net/sgWidget'
export const taxUrl = 'https://us-central1-fusioneta-softon-test.cloudfunctions.net/taxWidget'
//export const baseUrl = 'http://localhost:5001/Softon/us-central1/widgets3'
export const xeroLink = 'https://app.softon.io'
export const CLOUD_FUNCTION_WIDGETS_URL = "https://us-central1-fusioneta-softon-test.cloudfunctions.net/widgets3"
export const TAX_SOURCE_ID = 13
export const XERO_AC_REDIRECT_URI = "https://app.softon.io/xero-access"
export const STAGE = "prod"
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCfgc2_K8dTwtYrrnQxgcqL6G4PNRkl60c",
  authDomain: "fusioneta-softon-test.firebaseapp.com",
  databaseURL: "https://fusioneta-softon-test-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "fusioneta-softon-test",
  storageBucket: "fusioneta-softon-test.appspot.com",
  messagingSenderId: "62108854840",
  appId: "1:62108854840:web:d2e5541c28d68f346994cd"
};
